<template>
    <div style="width: 100%; height: 100%">
        <div style="width: 100%; height: 60px">
            <el-date-picker
                    style="margin: 15px 10px 10px 20px; height: 40px; width: auto; float: left"
                    v-model="selectDate"
                    @change="changeDate"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :shortcuts="shortcuts"
                    :default-time="defaultTime">
            </el-date-picker>
            <el-tooltip class="item" effect="dark" content="点击导出Excel文件" placement="bottom">
                <div style="float:left; font-size: 14px;width: 122px; height: 36px ; text-align: center; line-height: 36px;
                background-color: #20a0ff; color: #fff;border-radius:2px;cursor:pointer;margin: 15px 10px 10px 30px"
                     @click="downExcel">导出Excel</div>
            </el-tooltip>
<!--
            <div :class="[isactive === 1?'tv-selected':'tv-normal']" @click="initCharts(1)" style="margin: 20px 10px 10px 20px; float: left">最近24小时</div>
            <div :class="[isactive === 2?'tv-selected':'tv-normal']" @click="initCharts(2)" style="margin: 20px 10px 10px 20px; float: left">最近三天</div>
            <div :class="[isactive === 3?'tv-selected':'tv-normal']" @click="initCharts(3)" style="margin: 20px 10px 10px 20px; float: left">最近一周</div>
            <div :class="[isactive === 4?'tv-selected':'tv-normal']" @click="initCharts(4)" style="margin: 20px 10px 10px 20px; float: left">最近一月</div>-->
        </div>
        <div v-loading="loading" style="width: 100%; height: calc(100% - 110px); margin-top: 15px">
            <v-chart ref="chart1" style="width: 100%; height: 100%" class="chart" :option="option" :auto-resize="true"/>
        </div>

    </div>

</template>
<script>

    import VChart from "vue-echarts";
    import { ref } from "vue";
    import {formatDate} from '../js/formatDate.js';
  //  import { getDeviceHistoryByID, getDeviceInfo } from "../../api/index";
    export default {
        name: "DeviceHistory",
        components: {
            VChart
        },
        setup: () => {
            const xLabel = ref([]);
            const mydatas = ref([]);
            const device = ref({});
            const option = ref({
                title: {
                    text: "数据",
                    left: "20px"
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (params) {
                        let param = params[0];
                      //  console.error(param)
                        let tt = formatDate(new Date(parseInt(param.data[0])), 'yyyy-MM-dd hh:mm:ss');
                        let res = '时间 : ' + tt;
                        //使用for可以将需要的数据全部加到res
                        //注意下边的<br/>
                        for (let i = 0; i < params.length; i++) {
                            res += '<br/>' + params[i].seriesName + ' : ' + (device.value.type == 'RELAY' ? params[i].data[1] == 1 ? '打开' : '关闭' : params[i].data[1]) + device.value.unit;
                        }
                        return res;
                    }
                   // formatter: "{a} <br/>{b} : {c}"
                },
                xAxis: {
                    type: 'time',   // 还有其他的type，可以去官网喵两眼哦
                    boundaryGap: false,     //坐标轴两边不留白
                 //   data: xLabel,   // x轴数据
                    minInterval:1000,
                    name: '',   // x轴名称
                    axisLabel:{
                        formatter: function (value, index) {
                           // console.error(value, index)
                            return formatDate(new Date(parseInt(value)), 'yyyy-MM-dd\nhh:mm');
                        }
                    },
                    // x轴名称样式
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18,
                    },
                    axisLine: {             //坐标轴轴线相关设置。
                        //onZero: false,
                        lineStyle: {
                            color: '#7e7e7e',
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                 //   name: '纵轴名称',   // y轴名称
                    // y轴名称样式
                    nameTextStyle: {
                        fontWeight: 600,
                        fontSize: 18
                    },
                    axisLine: {             //坐标轴轴线相关设置。
                        lineStyle: {
                            color: '#7e7e7e',
                        }
                    },
                },
                legend: {
                    orient: "vertical",
                    right: "center",
                    data: ["实时数据"]
                },
                dataZoom: [     // 主要是这一部分，他是折线图的缩放功能的开启
                    {
                        type: 'inside',
                        start: 0,
                        end: 100,
                        bottom: '5px'
                    }, {
                        start: 0,
                        end: 100,
                        bottom: '5px'
                    }
                ],
                toolbox: {
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                       // restore: {},
                        saveAsImage: {}
                    }
                },
                series: [
                    {
                        name: "实时数据",
                        type: "line",
                        data: mydatas
                    }
                ]
            });

            return { option,xLabel,mydatas, device };
        },
        data () {
            return {
                id:this.$route.params.id,
                selectDate: [new Date(new Date().getTime()-24*60*60*1000), new Date()],
                valueList:[],
                isactive: 1,
                loading: true,
                defaultTime: [
                    new Date(2000, 1, 1, 0, 0, 0),
                    new Date(2000, 2, 1, 23, 59, 59)
                ],
                shortcuts:[
                    {
                        text:'最近24小时',
                        value:[new Date(new Date().getTime()-24*60*60*1000), new Date()]
                    },
                    {
                        text:'最近三天',
                        value:[new Date(new Date().getTime()-3*24*60*60*1000), new Date()]
                    },
                    {
                        text:'最近一周',
                        value:[new Date(new Date().getTime()-7*24*60*60*1000), new Date()]
                    },
                    {
                        text:'最近一月',
                        value:[new Date(new Date().getTime()-31*24*60*60*1000), new Date()]
                    }
                ]
            }
        },
        created(){
            this.getDeviceInfo()
            this.getDeviceHistoryByID()
        },
        methods: {
            downExcel() {
                this.exportExcel(this.valueList,
                    (this.device && this.device.belongDeviceGroupSN ? this.device.belongDeviceGroupSN : this.id)
                        + "_"+(this.device && this.device.name ? this.device.name : "数值")
                    + "_" + formatDate(this.selectDate[0], 'yyyy年MM月dd日hh点')
                    + "_"
                    + formatDate(this.selectDate[1], 'yyyy年MM月dd日hh点')
                    + "_记录",
                );
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(function (v) {
                    return filterVal.map(function (j) {
                        if (j == 'createTime') return formatDate(new Date(v[j]), 'yyyy-MM-dd hh:mm:ss');
                        if (j == 'value') return v[j] == 'ON' ? '打开'
                            : v[j] == 'OFF' ? '关闭'
                                : v[j];
                        return v[j]
                    })
                })
            },
            exportExcel(dataList, name) {
                require.ensure([], () => {
                    const {export_json_to_excel} = require('../../vendor/Export2Excel');
                    const tHeader = [this.device && this.device.name ? this.device.name : "数值" , '采集时间'];
                    const filterVal = ['value', 'createTime'];
                    const list = dataList;
                    const data = this.formatJson(filterVal, list);
                    export_json_to_excel(tHeader, data, name);
                })
            },
            initCharts(index){
                console.log(index)
                this.isactive = index;
                if (index == 1){
                    this.selectDate[0] = new Date(new Date().getTime()-24*60*60*1000);
                    this.selectDate[1] = new Date();
                }else if (index == 2){
                    this.selectDate[0] = new Date(new Date().getTime()-3*24*60*60*1000);
                    this.selectDate[1] = new Date();
                }else if (index == 3){
                    this.selectDate[0] = new Date(new Date().getTime()-7*24*60*60*1000);
                    this.selectDate[1] = new Date();
                }else if (index == 4){
                    this.selectDate[0] = new Date(new Date().getTime()-31*24*60*60*1000);
                    this.selectDate[1] = new Date();
                }

                this.getDeviceHistoryByID()
            },
            changeDate(val){
                this.getDeviceHistoryByID()
            },
            getDeviceInfo(){
                this.apiUtils.syDeviceInfo({id:this.id}).then(res => {
                    if (res.code == 200){
                        this.device = res.data
                        if (this.device.unit!=null && this.device.unit.length>0) {
                            this.option.title.text = this.device.name + "("+ this.device.unit+")";
                        }else{
                            this.option.title.text = this.device.name;
                        }
                        console.error( this.device)
                    } else {
                        this.$message.error("获取设备信息失败! " + res.errInfo);
                        console.log(res);
                    }

                }).finally(() => {});

            },
            getDeviceHistoryByID(){
                this.loading = true;
                this.apiUtils.syDeviceHistoryByID({id:this.id, startTime:this.selectDate[0].getTime(), endTime:this.selectDate[1].getTime()}).then(res => {
                    if (res.code == 200){
                        this.valueList = res.data
                        let labels = [];
                        let datas = [{
                            label: '数值',
                            data: []
                        }];
                        // let n = 0;
                        for (let valueListElement of this.valueList) {
                            labels.push(valueListElement.createTime)
                            datas[0].data.push([valueListElement.createTime, valueListElement.value == 'ON' ? 1
                                : valueListElement.value == 'OFF' ? 0
                                    : valueListElement.value] )
                        }
                        //  this.option.series[0].data = datas[0].data
                        //   this.option.xAxis.data = labels
                        this.xLabel = labels;
                        this.mydatas = datas[0].data;
                    } else {
                        this.$message.error("获取设备列表失败! " + res.errInfo);
                        console.log(res);
                    }

                }).finally(() => {this.loading = false;});

            },
            resizeTheChart () {
                if (this.$refs && this.$refs.chart1) {
                    this.$refs.chart1.resize();
                }
            }
        },
        mounted () {
            window.addEventListener("resize", this.resizeTheChart)
        },
        beforeUnmount () {
            window.removeEventListener("resize", this.resizeTheChart);
        },

    }
</script>

<style scoped>
    .schart-box {
        display: inline-block;
        margin: 20px;
    }
    .schart {
        width: 600px;
        height: 400px;
    }
    .content-title {
        clear: both;
        font-weight: 400;
        line-height: 50px;
        margin: 10px 0;
        font-size: 22px;
        color: #1f2f3d;
    }
</style>